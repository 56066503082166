<template>
    <v-app-bar app clipped-left color="primary" elevate-on-scroll>
        <v-app-bar-nav-icon
            @click="$emit('toggleNav')"
            dark
        ></v-app-bar-nav-icon>
        <app-logo-wrapper></app-logo-wrapper>
        <app-search v-if="can('search')"></app-search>
        <v-spacer></v-spacer>
        <app-header-menu></app-header-menu>

        <app-user></app-user>
    </v-app-bar>
</template>
<script>
import AppSearch from "@components/search/components/AppSearch.vue";
import AppLogoWrapper from "./components/AppLogoWrapper";
import AppUser from "@components/users/components/AppUser.vue";
import AppHeaderMenu from "./components/AppHeaderMenu.vue";
export default {
    name: "app-header",
    components: {
        AppSearch,
        AppLogoWrapper,
        AppUser,
        AppHeaderMenu,
    },
    props: {},
    data() {
        return {
            fab: false,
        };
    },
    methods: {},
    mounted() {
        this.$emit("mounted");
    },
};
</script>

<style scoped>
</style>