var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.menus.header, function(menu, index) {
        return [
          _c(
            "v-btn",
            _vm._b(
              {
                key: index,
                attrs: {
                  dark: "",
                  color: "primary darken-1",
                  disabled: _vm.$route.path === _vm.$_.get(menu, "to")
                }
              },
              "v-btn",
              _vm.$_.pick(menu, ["to", "href"]),
              false
            ),
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(menu.icon))
              ]),
              _c("span", { staticClass: "hidden-sm-and-down" }, [
                _vm._v(_vm._s(menu.title))
              ])
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }