var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      Object.keys(_vm.authUser).length > 0 && _vm.userMenu.length > 0
        ? _c(
            "psi-action-drop-down",
            _vm._b(
              {
                attrs: {
                  large: "",
                  "menu-avatar": _vm.authUser.profile_photo_url,
                  "heading-avatar": _vm.authUser.profile_photo_url,
                  "btn-class": _vm.btnClass,
                  items: _vm.userMenu,
                  heading: _vm.$_.get(_vm.authUser, "name", ""),
                  subheading: _vm.$_.get(_vm.authUser, "company.name", "")
                },
                on: { action: _vm.menuClicked }
              },
              "psi-action-drop-down",
              _vm.attrs,
              false
            )
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.can("impersonate.enable") && _vm.dialog
            ? _c("impersonate-user", {
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }