<template>
    <div class="title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex">
        <router-link :to="$_.get(homeUrl, 'to')" v-if="$_.has(homeUrl, 'to')">
            <app-logo></app-logo>
        </router-link>
        <a :href="$_.get(homeUrl, 'href')" v-if="$_.has(homeUrl, 'href')">
            <app-logo></app-logo>
        </a>
    </div>
</template>
<script>
import HomeLink from "@/mixins/homelink";
export default {
    name: "app-logo-wrapper",
    mixins: [HomeLink],
    components: {
        AppLogo: () => import("./AppLogo.vue"),
    },
};
</script>

<style scoped>
</style>