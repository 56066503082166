<template>
    <div>
        <psi-action-drop-down
            v-if="Object.keys(authUser).length > 0 && userMenu.length > 0"
            large
            :menu-avatar="authUser.profile_photo_url"
            :heading-avatar="authUser.profile_photo_url"
            :btn-class="btnClass"
            :items="userMenu"
            v-bind="attrs"
            :heading="$_.get(authUser, 'name', '')"
            :subheading="$_.get(authUser, 'company.name', '')"
            @action="menuClicked"
        ></psi-action-drop-down>
        <v-dialog v-model="dialog" max-width="600">
            <impersonate-user
                v-if="can('impersonate.enable') && dialog"
                @close="dialog = false"
            >
            </impersonate-user>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "app-user",
    components: {
        ImpersonateUser: () => import("./ImpersonateUser"),
    },
    props: {},
    data() {
        return {
            fab: false,
            dialog: false,
            userMenu: [],
        };
    },
    computed: {
        ...mapGetters("Users", ["menus"]),
        impersonating() {
            return this.$_.get(this.authUser, "impersonating", false);
        },
        impersonator() {
            return this.$_.get(this.authUser, "impersonator", {});
        },
        attrs() {
            return this.impersonating
                ? {
                      indicator: {
                          label: "Impersonating",
                          class: "warning grey--text text--darken-3",
                      },
                  }
                : {};
        },
        btnClass() {
            return this.impersonating
                ? "primary warning--text text--lighten-1"
                : "primary";
        },
    },
    watch: {
        menus: {
            immediate: true,
            handler(items) {
                if (this.$_.get(items, "user", []).length > 0) {
                    this.userMenu = items.user.map((item) => {
                        item.class = this.getItemClass(item.id);
                        if (item.id === "logout") {
                            delete item.href;
                        }
                        return item;
                    });
                    if (this.impersonating) {
                        this.userMenu.splice(
                            0,
                            0,
                            {
                                icon: "mdi-account-convert",
                                class: "warning--text text--darken-2",
                                id: "leave-impersonate",
                                text: "Stop Impersonating",
                                title: "Stop Impersonating",
                            },
                            {
                                divider: true,
                            }
                        );
                    }
                }
            },
        },
    },
    methods: {
        ...mapActions("Users", ["logout", "impersonateUser"]),
        menuClicked(item) {
            switch (item.id) {
                case "my-account":
                case "my-profile":
                    if (this.$_.get(item, "to", "")) {
                        if (this.$route.path !== item.to) {
                            this.$router.push({ path: item.to });
                        }
                    } else if (this.$_.get(item, "href", "")) {
                        window.location.replace(item.href);
                    }
                    break;
                case "logout":
                    this.logout();
                    break;
                case "impersonate":
                    this.dialog = true;
                    break;
                case "leave-impersonate":
                    this.leaveImpersonate();
                    break;
            }
        },
        getItemClass(id) {
            let itemClass = "";
            switch (id) {
                case "my-account":
                    itemClass = "primary--text text--darken-4";
                    break;
                case "logout":
                    itemClass = "error--text";
                    break;
            }
            return itemClass;
        },
        async leaveImpersonate() {
            const user = await this.impersonateUser({
                user_id: this.authUser.id,
                impersonate: false,
            });
            if (user.id === this.authUser.impersonator.id) {
                window.location.href = user.homeUrl.link.href;
            }
        },
    },
};
</script>

<style scoped>
</style>