var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex"
    },
    [
      _vm.$_.has(_vm.homeUrl, "to")
        ? _c(
            "router-link",
            { attrs: { to: _vm.$_.get(_vm.homeUrl, "to") } },
            [_c("app-logo")],
            1
          )
        : _vm._e(),
      _vm.$_.has(_vm.homeUrl, "href")
        ? _c(
            "a",
            { attrs: { href: _vm.$_.get(_vm.homeUrl, "href") } },
            [_c("app-logo")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }