<template>
    <div>
        <template v-for="(menu, index) in menus.header">
            <v-btn
                :key="index"
                dark
                color="primary darken-1"
                :disabled="$route.path === $_.get(menu, 'to')"
                v-bind="$_.pick(menu, ['to', 'href'])"
            >
                <v-icon class="mr-1">{{ menu.icon }}</v-icon>
                <span class="hidden-sm-and-down">{{ menu.title }}</span>
            </v-btn>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("Users", ["menus"]),
    },
};
</script>

<style lang="scss" scoped>
</style>